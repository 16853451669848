import { createAction } from "@reduxjs/toolkit";

const ACTION_TYPES = {
  CREATE_GOAL_INITIATED: 'CREATE_GOAL_INITIATED',
  CREATE_GOAL_SUCCEEDED: 'CREATE_GOAL_SUCCEEDED',
  CREATE_GOAL_FAILED: 'CREATE_GOAL_FAILED',
  UPDATE_GOAL_INITIATED: 'UPDATE_GOAL_INITIATED',
  UPDATE_GOAL_SUCCEEDED: 'UPDATE_GOAL_SUCCEEDED',
  UPDATE_GOAL_FAILED: 'UPDATE_GOAL_FAILED',
  DELETE_GOAL_INITIATED: 'DELETE_GOAL_INITIATED',
  DELETE_GOAL_SUCCEEDED: 'DELETE_GOAL_SUCCEEDED',
  DELETE_GOAL_FAILED: 'DELETE_GOAL_FAILED',
};

export const GOAL_ACTION_TYPES = ACTION_TYPES;

// Actions

export const initCreateGoal = createAction(ACTION_TYPES.CREATE_GOAL_INITIATED);
export const createGoalSucceeded = createAction(ACTION_TYPES.CREATE_GOAL_SUCCEEDED);
export const createGoalFailed = createAction(ACTION_TYPES.CREATE_GOAL_FAILED);

export const initUpdateGoal = createAction(ACTION_TYPES.UPDATE_GOAL_INITIATED);
export const updateGoalSucceeded = createAction(ACTION_TYPES.UPDATE_GOAL_SUCCEEDED);
export const updateGoalFailed = createAction(ACTION_TYPES.UPDATE_GOAL_FAILED);

export const initDeleteGoal = createAction(ACTION_TYPES.DELETE_GOAL_INITIATED);
export const deleteGoalSucceeded = createAction(ACTION_TYPES.DELETE_GOAL_SUCCEEDED);
export const deleteGoalFailed = createAction(ACTION_TYPES.DELETE_GOAL_FAILED);