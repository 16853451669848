import { createAction } from "@reduxjs/toolkit";

const ACTION_TYPES = {
  CREATE_PROJECT_INITIATED: 'CREATE_PROJECT_INITIATED',
  CREATE_PROJECT_SUCCEEDED: 'CREATE_PROJECT_SUCCEEDED',
  CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',
  UPDATE_PROJECT_INITIATED: 'UPDATE_PROJECT_INITIATED',
  UPDATE_PROJECT_SUCCEEDED: 'UPDATE_PROJECT_SUCCEEDED',
  UPDATE_PROJECT_FAILED: 'UPDATE_PROJECT_FAILED',
  DELETE_PROJECT_INITIATED: 'DELETE_PROJECT_INITIATED',
  DELETE_PROJECT_SUCCEEDED: 'DELETE_PROJECT_SUCCEEDED',
  DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',
};

export const PROJECT_ACTION_TYPES = ACTION_TYPES;

// Actions

export const initCreateProject = createAction(ACTION_TYPES.CREATE_PROJECT_INITIATED);
export const createProjectSucceeded = createAction(ACTION_TYPES.CREATE_PROJECT_SUCCEEDED);
export const createProjectFailed = createAction(ACTION_TYPES.CREATE_PROJECT_FAILED);

export const initUpdateProject = createAction(ACTION_TYPES.UPDATE_PROJECT_INITIATED);
export const updateProjectSucceeded = createAction(ACTION_TYPES.UPDATE_PROJECT_SUCCEEDED);
export const updateProjectFailed = createAction(ACTION_TYPES.UPDATE_PROJECT_FAILED);

export const initDeleteProject = createAction(ACTION_TYPES.DELETE_PROJECT_INITIATED);
export const deleteProjectSucceeded = createAction(ACTION_TYPES.DELETE_PROJECT_SUCCEEDED);
export const deleteProjectFailed = createAction(ACTION_TYPES.DELETE_PROJECT_FAILED);