import React from 'react';
import './GoalCard.scss';
import { Card } from 'antd';
import { TbClock, TbList } from 'react-icons/tb';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function GoalCard({
  id,
  title,
  description,
  dueDate,
  tasksCount,
}) {
  return (
    <Card className="single-goal">
      <h3>
        <Link to={`/goals/${id}`}>{title}</Link>
      </h3>
      <p>{description}</p>
      <div className="goal-details">
        <span className="detail">
          <TbClock size={18} strokeWidth={1.3} />
          <span>Due Date — {moment(dueDate).format('DD MMM YYYY')}</span>
        </span>
        <span className="detail">
          <TbList size={18} strokeWidth={1.3} />
          <span>Total Tasks — {tasksCount || 0}</span>
        </span>
      </div>
    </Card>
  );
}
