import React from 'react';
import './UIButton.scss';

export default function UIButton({ icon, title, onClick, type = 'button' }) {
  const Icon = icon;
  return (
    <button className="ui-btn" onClick={onClick} type={type}>
      { icon && <Icon size={18} /> }
      <span className="title">{title}</span>
    </button>
  );
}
