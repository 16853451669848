import React from 'react';
import './ProjectCard.scss';
import {
  TbCalendarEvent,
  TbClockEdit,
  TbDotsVertical,
  TbEdit,
  TbHomeDown,
} from 'react-icons/tb';
import moment from 'moment';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import {
  MODAL_TYPES,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import { Link } from 'react-router-dom';

export default function ProjectCard({
  id,
  title,
  color,
  description,
  createdAt,
  dueDate,
}) {
  const dispatch = useDispatch();
  const items = [
    {
      icon: <TbEdit />,
      label: 'Edit',
      key: 'edit',
    },
  ];

  const onMenuClick = (e) => {
    switch (e.key) {
      case 'edit':
        dispatch(
          showModalAction({
            modalType: MODAL_TYPES.CREATE_PROJECT_MODAL,
            modalData: {
              projectId: id,
            },
          })
        );
        return;
      default:
        console.log(e.key);
    }
  };

  return (
    <div className="project-card">
      <div className="project-card-header">
        <div
          className="color-dot"
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div className="project-title">
          <Link to={`/projects/${id}`}>
            {title}
          </Link>
        </div>
        <Dropdown
          menu={{
            items,
            onClick: onMenuClick,
          }}
          trigger={['click']}
        >
          <button>
            <TbDotsVertical size={18} strokeWidth={1.2} />
          </button>
        </Dropdown>
      </div>
      <div className="project-desc">{description || '-'}</div>
      <div className="project-details">
        <div className="single-detail">
          <TbClockEdit size={18} strokeWidth={1.2} />
          {/* <span className="label">Created</span> */}
          <span className="value">
            {moment(createdAt).format('DD MMM, YYYY')}
          </span>
        </div>

        {/* <div className="single-detail">
          <TbCalendarEvent size={18} strokeWidth={1.2} />
          <span className="value">
            {moment(dueDate).format('DD MMM, YYYY')}
          </span>
        </div> */}
      </div>
    </div>
  );
}
