import React from 'react';
import { TbChevronDown, TbChevronRight, TbFolderOpen } from 'react-icons/tb';
import './PageSubHeader.scss';

export default function PageSubHeader({ title, icon, heading }) {
  const Icon = icon || TbFolderOpen;
  return (
    <div className="page-sub-header">
      <div className="main-page-header">
        <Icon size={24} />
        <span className="title">{title}</span>
      </div>
      {heading && (
        <>
          <div className="page-divider">
            <TbChevronRight size={16} />
          </div>
          <div className="subpage-header">
            <span className="title">{heading}</span>
            <TbChevronDown size={14} />
          </div>
        </>
      )}
    </div>
  );
}
