import { Model, fk, attr } from 'redux-orm';
import { TASK_ACTION_TYPES } from '../actions/task.actions';

class Task extends Model {
  static reducer(action, Task) {
    switch (action.type) {
      case TASK_ACTION_TYPES.CREATE_TASK_SUCCEEDED:
        Task.create(action.payload);
        break;
      case TASK_ACTION_TYPES.UPDATE_TASK_SUCCEEDED:
        const { id, update } = action.payload;
        console.log(action.payload);
        Task.withId(id).update(update);
        break;
      case TASK_ACTION_TYPES.DELETE_TASK_SUCCEEDED:
        Task.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }
}
Task.modelName = 'Task';

Task.fields = {
  title: attr(),
  description: attr(),
  status: attr(),
  complete: attr(),
  project: fk('Project', 'tasks'),
  parent: fk('Task', 'subtasks'),
  dueDate: attr(),
  list: fk('List', 'tasks'),
  goal: fk('Goal', 'tasks'),
  priority: attr(),
};

export default Task;
