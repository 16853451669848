import React from 'react';
import './TaskList.scss';
import SingleTask from '../SingleTask/SingleTask';
import { TbAntennaBars5, TbCaretDown, TbCaretRight, TbCaretUp, TbPlus } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import {
  MODAL_TYPES,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import { Button, Empty } from 'antd';
import UIDropdown from '../../ui/UIDropdown/UIDropdown';
import ProjectDot from '../../ui/ProjectDot/ProjectDot';
import { formatTime } from '../../../utils';


export default function TaskList({
  listId,
  projectId,
  goalId,
  title = 'My Tasks',
  tasks = [],
  groupBy,
}) {
  const [expanded, setExpanded] = React.useState(true);
  const dispatch = useDispatch();

  console.log('groupby', groupBy);

  const showAddTaskModal = () => {
    dispatch(
      showModalAction({
        modalType: MODAL_TYPES.CREATE_TASK_MODAL,
        modalData: {
          projectId: projectId,
          goalId: goalId,
          listId: listId,
        },
      })
    );
  };

  const totalTime = tasks.reduce((acc, task) => {
    return acc + parseInt(task.estimate);
  }, 0);

  return (
    <div className="task-list-wrapper">
      <div className="task-list-header">
        <div className="task-list-title">
          <button
            className="expand-collapse-btn"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <TbCaretRight fill="#222" size={18} />
            ) : (
              <TbCaretDown fill="#222" size={18} />
            )}
          </button>
          <span className="list-title">
            {title}
          </span>
          <span className="estimated-time">
            { formatTime(totalTime) }
          </span>
        </div>
      </div>
      {expanded && (
        <div>
          {/* {tasks.length > 0 && ( */}
          <>
            <div className="task-list-content">
              {tasks.map((task) => (
                <SingleTask
                  key={task.id}
                  id={task.id}
                  title={task?.title}
                  complete={task.complete}
                  project={task.projectDetails}
                  subtasks={task.subtasks}
                  dueDate={task.dueDate}
                  priority={task.priority}
                  estimate={task.estimate}
                  goal={task.goal}
                />
              ))}
              <div className="add-task-trigger" onClick={showAddTaskModal}>
                <TbPlus size={18} />
                <span>Add new Task</span>
              </div>
            </div>
          </>
          {/* )} */}
          {tasks.length === 0 && (
            <div className="empty-task-list">
              {/* <Empty
              description={
                <div>
                  <p>You have no tasks. Let's add some!</p>
                  <Button type="primary" onClick={showAddTaskModal}>
                    Add new Task
                  </Button>
                </div>
              }
            /> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
