import React from 'react';
import MainContent from '../../MainContent/MainContent';
import TaskList from '../../../components/tasks/TaskList/TaskList';
import { useSelector } from 'react-redux';
import { selectPastDueTasks, selectTodayTasks } from '../../../redux/selectors/task.selectors';
import moment from 'moment';
import { selectThisWeekTasks, selectTomorrowTasks } from '../../../redux/selectors/task.selectors';

export default function PastDuePage() {
  const tasks = useSelector(selectPastDueTasks);

  return (
    <MainContent title={`Past Due`}>
      <TaskList tasks={tasks} />
    </MainContent>
  );
}
