import { call, put } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { createGoalFailed, createGoalSucceeded, deleteGoalFailed, deleteGoalSucceeded, updateGoalFailed, updateGoalSucceeded } from "../actions/goal.actions";
import { message } from "antd";

export function* createGoalRequest(values) {
  try {
    const id = uuid();
    const list = {
      id,
      ...values,
    };
    yield put(createGoalSucceeded(list));
    message.success("Goal created successfully");
  } catch (error) {
    console.log(error);
    yield put(createGoalFailed({ error }));
  }
}

export function* updateGoalRequest(id, update) {
  try {
    yield put(updateGoalSucceeded({ id, update }));
    message.success("Goal updated successfully");
  } catch (error) {
    yield put(updateGoalFailed({ error }));
  }
}
export function* deleteGoalRequest(id) {
  console.log('dd', id);
  try {
    yield put(deleteGoalSucceeded({ id }));
    message.success("Goal deleted successfully");
  } catch (error) {
    yield put(deleteGoalFailed({ error }));
  }
}
