import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';

import storage from 'redux-persist/lib/storage';
// import { PersistGate } from "redux-persist/integration/react";

import rootReducer from './reducers';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import sagas from './sagas';
import createMigrate from 'redux-persist/es/createMigrate';
import orm from './orm';
const sagaMiddleware = createSagaMiddleware();

const VERSION = 3;

const migration = (state) => {
  try {
    // Clone the existing state
    const migratedState = { ...state };
  
    // Get all registered models from the ORM
    const registeredModels = orm.getModelClasses();
    
      // Initialize missing models in the state
      registeredModels.forEach((ModelClass) => {
      if (!migratedState['orm'][ModelClass.modelName]) {
        // Assuming each model has a static method to return a default state
        migratedState['orm'][ModelClass.modelName] = {
          items: [],
          itemsById: {},
          indexes: {},
          meta: {}
        }
      }
    });
    console.log('rod-migratedState', migratedState);
      
    return migratedState;
  } catch (error) {
    console.error('rod-Error migrating state:', error);
  }
};

const migrations = {
  [VERSION]: migration,
};

const persistConfig = {
  key: 'root',
  version: VERSION,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  // reducer: rootReducer,
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleWare) => {
    return getDefaultMiddleWare({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(sagaMiddleware);
  },
});

let persistorStore = persistStore(store);

initStateWithPrevTab(store);
sagaMiddleware.run(sagas);

export default store;
// export default persistor;
export const persistor = persistorStore;
