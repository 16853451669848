import { createSelector as createOrmSelector } from 'redux-orm';
import orm from '../orm';
import moment from 'moment';

export const selectAllTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all().toRefArray();
});

export const populateTask = (task) => {
  return {
    ...task.ref,
    projectDetails: task?.project?.ref,
    project: task?.project?.id,
    subtasks: task?.subtasks?.toRefArray(),
  };
};

export const countAllTasks = createOrmSelector(
  orm,
  selectAllTasks,
  (_, tasks) => {
    return tasks.length;
  }
);

export const selectTodayTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all()
    .filter((task) => {
      console.log(task.parent);
      return task.dueDate && moment(task.dueDate).isSame(moment(), 'day') && !task.parent;
    })
    .toModelArray()
    .map(populateTask);
});

export const countTodayTasks = createOrmSelector(
  orm,
  selectTodayTasks,
  (_, tasks) => {
    return tasks.length;
  }
);

export const selectPastDueTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all()
    .filter((task) => {
      return (
        task.dueDate && moment(task.dueDate).isBefore(moment(), 'day') && task.complete !== true
      );
    })
    .toModelArray()
    .map(populateTask);
});

export const countPastDueTasks = createOrmSelector(
  orm,
  selectPastDueTasks,
  (_, tasks) => {
    return tasks.length;
  }
);

export const selectTomorrowTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all()
    .filter((task) => {
      return task.dueDate && moment(task.dueDate).isSame(moment().add(1, 'days'), 'day');
    })
    .toModelArray()
    .map(populateTask);
});

export const countTomorrowTasks = createOrmSelector(
  orm,
  selectTomorrowTasks,
  (_, tasks) => {
    return tasks.length;
  }
);

export const selectThisWeekTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all().filter(task => {
    return task.dueDate && moment(task.dueDate).isBetween(moment(), moment().endOf('week'), 'day', '[]');
  }).toModelArray().map(populateTask);
});

export const countThisWeekTasks = createOrmSelector(
  orm,
  selectThisWeekTasks,
  (_, tasks) => {
    return tasks.length;
  }
);

export const selectNextTasks = createOrmSelector(orm, ({ Task }) => {
  return Task.all().toModelArray().map(populateTask);
});

export const countNextTasks = createOrmSelector(
  orm,
  selectNextTasks,
  (_, tasks) => {
    return tasks.length;
  }
);
