import { ORM } from "redux-orm";
import Task from "./models/task.model";
import Project from "./models/project.model";
import List from "./models/list.model";
import Goal from "./models/goal.model";
const orm = new ORM({
  stateSelector: (state) => state.orm,
});
orm.register(Task);
orm.register(Project);
orm.register(List);
orm.register(Goal);

export default orm;
