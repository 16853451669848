import React from 'react';
import MainContent from '../../MainContent/MainContent';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSingleProject,
  selectSingleProjectWithData,
} from '../../../redux/selectors/project.selectors';
import TaskList from '../../../components/tasks/TaskList/TaskList';
import {
  MODAL_TYPES,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import Navigation from '../../../components/ui/Navigation/Navigation';
import { TbFolderOpen, TbFolders, TbList, TbTarget } from 'react-icons/tb';
import PageSubHeader from '../../../components/ui/PageSubHeader/PageSubHeader';

export default function ProjectSinglePage() {
  const params = useParams();
  const projectId = params?.id;
  const dispatch = useDispatch();

  const project = useSelector(selectSingleProjectWithData(projectId));

  console.log('project', project);

  const showCreateListModal = () => {
    dispatch(
      showModalAction({
        modalType: MODAL_TYPES.CREATE_LIST_MODAL,
        modalData: {
          projectId: project?.id,
        },
      })
    );
  };

  return (
    <div>
      {project?.lists?.map((list) => (
        <TaskList
          tasks={list.tasks}
          projectId={project?.id}
          listId={list.id}
          key={list.id}
          title={list.title}
        />
      ))}
      <button onClick={showCreateListModal}>Create List</button>
    </div>
  );
}
