import React from 'react';
import MainContent from '../../MainContent/MainContent';
import PageSubHeader from '../../../components/ui/PageSubHeader/PageSubHeader';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectSingleProjectWithData } from '../../../redux/selectors/project.selectors';
import Navigation from '../../../components/ui/Navigation/Navigation';
import { TbFolderOpen, TbList, TbTarget } from 'react-icons/tb';

export default function ProjectPageLayout() {
    const params = useParams();
    const projectId = params?.id;
    const dispatch = useDispatch();
  
    const project = useSelector(selectSingleProjectWithData(projectId));
  return (
    <MainContent
      title={<PageSubHeader title={'Projects'} heading={project?.title} />}
      navigation={
        <Navigation
          items={[
            // {
            //   label: 'Overview',
            //   icon: TbFolderOpen,
            //   link: `/projects/${project?.id}`,
            // },
            {
              label: 'Tasks',
              icon: TbList,
              link: `/projects/${project?.id}/tasks`,
            },
            {
              label: 'Goals',
              icon: TbTarget,
              link: `/projects/${project?.id}/goals`,
            },
          ]}
        />
      }
    >
      <Outlet />
    </MainContent>
  );
}
