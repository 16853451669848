import { Model, fk, many, attr } from 'redux-orm';
import { PROJECT_ACTION_TYPES } from '../actions/project.actions';

class Project extends Model {
  static reducer(action, Project) {
    switch (action.type) {
      case PROJECT_ACTION_TYPES.CREATE_PROJECT_SUCCEEDED:
        Project.create(action.payload);
        break;
      case PROJECT_ACTION_TYPES.UPDATE_PROJECT_SUCCEEDED:
        const { id, update } = action.payload;
        console.log(action.payload);
        Project.withId(id).update(update);
        break;
      default:
        break;
    }
  }
}
Project.modelName = 'Project';

Project.fields = {
  title: attr(),
  description: attr(),
  color: attr(),
  status: attr(),
  complete: attr(),
  dueDate: attr(),
  subtasks: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  parent: fk('Project', 'subprojects'),
};

export default Project;
