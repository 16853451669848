import React from 'react';
import './Sidebar.scss';
import { Link, NavLink } from 'react-router-dom';
import {
  TbAlertOctagon,
  TbCalendarDue,
  TbCalendarStar,
  TbChevronRight,
  TbClipboardData,
  TbCrosshair,
  TbFolderOpen,
  TbHome,
  TbLayoutList,
  TbTarget,
  TbWand,
} from 'react-icons/tb';
import { Badge } from 'antd';
import { useSelector } from 'react-redux';
import { countAllTasks, countNextTasks, countPastDueTasks, countSelectTodayTasks, countThisWeekTasks, countTodayTasks, countTomorrowTasks, selectPastDueTasks } from '../../../redux/selectors/task.selectors';

const navigation = [
  {
    name: 'Today',
    path: '/',
    icon: TbLayoutList,
  },
  {
    name: 'Tomorrow',
    path: '/tomorrow',
    icon: TbClipboardData,
  },
  {
    name: 'This Week',
    path: '/week',
    icon: TbCalendarStar,
  },
  {
    name: 'Someday',
    path: '/someday',
    icon: TbCalendarDue,
  },
];

const MainMenu = ({ title, link = '/', icon, children }) => {
  const Icon = icon;
  return (
    <div className="main-menu">
      <div className="main-menu-header">
        <Link to={link}>
          <Icon size={24} strokeWidth={1.25} />
          <span className="text">{title}</span>
          <span className="icon-right">
            <TbChevronRight size={18} strokeWidth={1.25} />
          </span>
        </Link>
      </div>
      <div className="main-menu-content">{children}</div>
    </div>
  );
};

export default function Sidebar() {
  const pastTasksCount = useSelector(countPastDueTasks);

  const allTasksCount = useSelector(countAllTasks);
  const todayTasksCount = useSelector(countTodayTasks);
  const tomorrowTasksCount = useSelector(countTomorrowTasks);
  const thisWeekTasksCount = useSelector(countThisWeekTasks);
  const somedayTasksCount = useSelector(countNextTasks);

  if (pastTasksCount > 0) {
    const pastPushed = navigation.find((item) => item.name === 'Past Due');
    if(!pastPushed) {
      navigation.unshift({
        name: 'Past Due',
        path: '/past',
        icon: TbAlertOctagon,
      });
    }
  }
  if(pastTasksCount === 0) {
    const pastPushed = navigation.find((item) => item.name === 'Past Due');
    if(pastPushed) {
      navigation.shift();
    }
  }

  const getTasksCount = (item) => {
    switch (item.name) {
      case 'Today':
        return todayTasksCount;
      case 'Tomorrow':
        return tomorrowTasksCount;
      case 'Someday':
        return somedayTasksCount;
      case 'Past Due':
        return pastTasksCount;
      case 'This Week':
        return thisWeekTasksCount;
      default:
        return 0;
    }
  }

  return (
    <div className="sidebar">
      <div className="logo-wrapper">
        <img src="/taskwall-logo.svg" alt="logo" />
      </div>
      <div className="search-wrapper">
        <input placeholder="Search..." />
      </div>
      <div className="main-menu-wrapper">
        <ul>
          {navigation.map((item, index) => {
            const Icon = item.icon;
            return (
              <li key={item}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  {item.icon}
                  <Icon size={24} strokeWidth={1.25} />
                  <span className="text">{item.name}</span>
                  <Badge count={getTasksCount(item).toString().padStart(2, '0')} style={{ marginLeft: 'auto' }} />
                </NavLink>
              </li>
            );
          })}
        </ul>
        <hr />
        <ul>
          <li>
            <MainMenu title={'Goals'} link='/goals' icon={TbTarget}></MainMenu>
          </li>
          <li>
            <MainMenu title={'Projects'} link='/projects' icon={TbFolderOpen}></MainMenu>
          </li>
          {/* <li>
            <MainMenu title={'Strategies'} link='/strategies' icon={TbWand}></MainMenu>
          </li> */}
        </ul>
        <hr />
      </div>
      {/* <div className='create-account'>
        <p>Create a free account to sync your data across all your devices.</p>
        <button className='btn btn-primary'>Create Account</button>
      </div> */}
    </div>
  );
}
