import { Form, Input, Modal, Select } from 'antd';
import React from 'react';
import UIButton from '../../../components/ui/UIButton/UIButton';
import { useDispatch, useSelector } from 'react-redux';
import { initCreateList } from '../../../redux/actions/list.actions';
import { hideModalAction } from '../../../redux/reducers/ui.reducer';

export default function CreateListModal({ data }) {
  const dispatch = useDispatch();

  const hideModal = () => {
    dispatch(hideModalAction())
  }

  const handleSubmit = (values) => {
    dispatch(initCreateList({
      title: values.title,
      project: data.projectId,
      goal: data.goalId,
    }));
    hideModal();
  };

  return (
    <Modal open={true} title="Create a List" footer={false} onCancel={hideModal}>
      <Form onFinish={handleSubmit} layout='vertical'>
        <Form.Item name={'title'} label="List Title">
          <Input placeholder="Product Launch" />
        </Form.Item>
        <Form.Item>
          <UIButton type="submit" title={'Create List'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
