import React from 'react';
import { useSelector } from 'react-redux';
import { selectModalActive } from '../../../redux/selectors/ui.selector';
import { MODAL_TYPES } from '../../../redux/reducers/ui.reducer';
import CreateTaskModal from '../CreateTaskModal/CreateTaskModal';
import CreateProjectModal from '../CreateProjectModal/CreateProjectModal';
import CreateListModal from '../CreateListModal/CreateListModal';
import CreateGoalModal from '../CreateGoalModal/CreateGoalModal';

export default function ModalWrapper() {
  const modalActive = useSelector(selectModalActive);
  console.log('modalActive', modalActive);

  // if (!modalActive) {
  //   return;
  // }

  return (
    <div>
      {modalActive?.type === MODAL_TYPES.CREATE_TASK_MODAL && (
        <CreateTaskModal data={modalActive?.data} />
      )}
      {modalActive?.type === MODAL_TYPES.CREATE_PROJECT_MODAL && (
        <CreateProjectModal data={modalActive?.data} />
      )}
      {modalActive?.type === MODAL_TYPES.CREATE_LIST_MODAL && (
        <CreateListModal data={modalActive?.data} />
      )}
      {modalActive?.type === MODAL_TYPES.CREATE_GOAL_MODAL && (
        <CreateGoalModal data={modalActive?.data} />
      )}
    </div>
  );
}
