import { Model, fk, attr } from 'redux-orm';
import { GOAL_ACTION_TYPES } from '../actions/goal.actions'

class Goal extends Model {
  static reducer(action, Goal) {
    switch (action.type) {
      case GOAL_ACTION_TYPES.CREATE_GOAL_SUCCEEDED:
        Goal.create(action.payload);
        break;
      case GOAL_ACTION_TYPES.UPDATE_GOAL_SUCCEEDED:
        Goal.withId(action.payload.id).update(action.payload);
        break;
      case GOAL_ACTION_TYPES.DELETE_GOAL_SUCCEEDED:
        Goal.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }
}
Goal.modelName = 'Goal';

Goal.fields = {
  title: attr(),
  description: attr(),
  complete: attr(),
  project: fk('Project', 'goals'),
  dueDate: attr()
};

export default Goal;
