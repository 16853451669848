import { Card, Col, Modal, Row } from 'antd';
import React from 'react';
import './WelcomeModal.scss';
import UIButton from '../../../components/ui/UIButton/UIButton';

export default function WelcomeModal() {
  return (
    <Modal
      open={false}
      footer={false}
      title={false}
      closable={false}
      width={1000}
    >
      <div className="welcome-modal-starts">
        <div className="welcome-modal-header">
          <h1>Your Personalized Productivity Manager</h1>
          <p className="lead">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            quam sit minima rerum nesciunt. Et quos quae rerum laudantium ad.
          </p>
        </div>
        <div className="welcome-modal-main-content">
          <Row gutter={20}>
            <Col span={8}>
              <Card>
                <h3>
                  Designed for busy professionals looking to boost their
                  productivity.
                </h3>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h3>
                  Implement from 100s of Productivity Strategies and Techniques.
                </h3>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h3>
                Start organizing your life immediately - no sign-up required. 
                </h3>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="welcome-modal-action">
          <p>Begin Your Day with Taskwall</p>
          <UIButton title={'Get Started'} />
        </div>
        <div>

        </div>
      </div>
    </Modal>
  );
}
