import React from 'react';
import MainContent from '../MainContent/MainContent';
import TaskList from '../../components/tasks/TaskList/TaskList';
import { useDispatch, useSelector } from 'react-redux';
import { selectTodayTasks } from '../../redux/selectors/task.selectors';
import moment from 'moment';
import { Button, Checkbox, Dropdown } from 'antd';
import {
  VIEW_CONFIG,
  updateViewConfigValue,
} from '../../redux/reducers/ui.reducer';
import { selectViewConfig } from '../../redux/selectors/ui.selector';
import UIDropdown from '../../components/ui/UIDropdown/UIDropdown';
import { TbChevronDown, TbSearch } from 'react-icons/tb';
import { selectAllProjects } from '../../redux/selectors/project.selectors';
import { selectAllGoals } from '../../redux/selectors/goal.selectors';
import { formatTime } from '../../utils';

export default function DashboardPage() {
  const dispatch = useDispatch();
  const tasks = useSelector(selectTodayTasks);
  const date = moment().format('ddd, DD MMM');
  const hideCompleted =
    useSelector(selectViewConfig)[VIEW_CONFIG.MYTASKS_HIDE_COMPLETED_TASKS];

  const groupBy = useSelector(selectViewConfig)[VIEW_CONFIG.MYTASKS_GROUP_BY];
  const projects = useSelector(selectAllProjects);
  const goals = useSelector(selectAllGoals);

  console.log('tasks', tasks);

  const handleToggleChange = (e) => {
    dispatch(
      updateViewConfigValue({
        key: VIEW_CONFIG.MYTASKS_HIDE_COMPLETED_TASKS,
        value: e.target.checked,
      })
    );
  };

  const filterTasks = (tasks) => {
    if (!hideCompleted) return tasks;
    return tasks.filter((task) => task.complete !== true);
  };

  const handleMenuUpdate = (key) => {
    dispatch(
      updateViewConfigValue({
        key: VIEW_CONFIG.MYTASKS_GROUP_BY,
        value: key,
      })
    );
  };

  const groupTasks = (tasks) => {
    // Use GroupBy and return groups of tasks
    if(!groupBy) return { 'default': tasks };
    const groups = tasks.reduce((groups, task) => {
      const key = task[groupBy];
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(task);
      return groups;
    }, {});
    return groups;
  };

  const getGroupedTasks = () => {
    const groupByVal = groupBy?.toLowerCase() || 'null';
    console.log('groupBy', groupByVal);
    if (groupByVal === 'priority') {
      return [
        {
          label: 'High',
          value: 'high',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Low',
          value: 'low',
        },
      ];
    }
    if (groupByVal === 'project') {
      return projects.map((project) => {
        return {
          label: project.title,
          value: project.id,
        }
      })
    }
    if (groupByVal === 'goal') {
      console.log('getGroupedTasks-goals', goals);
      return goals.map((goal) => {
        return {
          label: goal.title,
          value: goal.id,
        }
      })
    } 
    if (groupByVal === 'estimate') {
      let estimates = tasks.reduce((acc, task) => {
        if (!acc.includes(task.estimate)) {
          acc.push(task.estimate);
        }
        return acc;
      }
      , []);
      estimates = estimates.sort((a, b) => a - b);
      return estimates.map((estimate) => {
        return {
          label: formatTime(estimate),
          value: estimate,
        }
      });
    } 
    return [{
      label: 'My Tasks',
      value: 'default'
    }]
  };

  console.log('getGroupedTasks', getGroupedTasks());

  return (
    <MainContent
      title={`Today —  ${date}`}
      navigation={
        <div className="header-navigation">
          <Dropdown
            menu={{
              items: [
                {
                  key: 'priority',
                  label: 'Priority',
                },
                {
                  key: 'goal',
                  label: 'Goal',
                },
                {
                  key: 'project',
                  label: 'Project',
                },
                {
                  key: 'estimate',
                  label: 'Estimate',
                },
              ],
              onClick: ({ key }) => {
                handleMenuUpdate(key);
              },
            }}
            trigger={['click']}
          >
            <Button className="dropdown-trigger">
              <span>{groupBy ? groupBy : 'Group By'}</span>
              <TbChevronDown />
            </Button>
          </Dropdown>
        </div>
      }
      actions={
        <div
          style={{
            userSelect: 'none',
          }}
        >
          <Checkbox onChange={handleToggleChange} checked={hideCompleted}>
            Hide completed tasks
          </Checkbox>
        </div>
      }
    >
      {getGroupedTasks().map((group) => {
        return (
          <TaskList
            key={group.label}
            title={group.label}
            tasks={filterTasks(groupTasks(tasks)[group.value] || [])}
            groupBy={groupBy}
          />
        );
      })}
    </MainContent>
  );
}
