import { all, takeLatest } from 'redux-saga/effects';
import {
  createTaskRequest,
  updateTaskRequest,
  deleteTaskRequest,
} from '../requests/task.requests';
import { TASK_ACTION_TYPES } from '../actions/task.actions';

function* taskWatchers() {
  yield all([
    takeLatest(TASK_ACTION_TYPES.CREATE_TASK_INITIATED, ({ payload }) => {
      return createTaskRequest(payload);
    }),
    takeLatest(
      TASK_ACTION_TYPES.UPDATE_TASK_INITIATED,
      ({ payload: { id, ...rest } }) => {
        return updateTaskRequest(id, rest);
      }
    ),
    takeLatest(
      TASK_ACTION_TYPES.DELETE_TASK_INITIATED,
      ({ payload: { id } }) => {
        return deleteTaskRequest(id);
      }
    ),
  ]);
}

export default taskWatchers;
