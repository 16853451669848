import React from 'react';
import './MainContent.scss';

export default function MainContent({ title, navigation, children, actions }) {
  return (
    <div className="main-content-component">
      <div className="main-content-header">
        <h2>{title}</h2>
        { navigation && <div className='navigation-wrapper'>
          { navigation }
        </div> }
        <div className="actions-wrapper">
          { actions }
        </div>
      </div>
      <div className="main-content-body">{children}</div>
    </div>
  );
}
