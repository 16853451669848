import { all, takeLatest } from 'redux-saga/effects';
import {
  createListRequest,
  updateListRequest,
  deleteListRequest,
} from '../requests/list.requests';
import { LIST_ACTION_TYPES } from '../actions/list.actions';

function* taskWatchers() {
  yield all([
    takeLatest(LIST_ACTION_TYPES.CREATE_LIST_INITIATED, ({ payload }) => {
      return createListRequest(payload);
    }),
    takeLatest(
      LIST_ACTION_TYPES.UPDATE_LIST_INITIATED,
      ({ payload: { id, ...rest } }) => {
        return updateListRequest(id, rest);
      }
    ),
    takeLatest(
      LIST_ACTION_TYPES.DELETE_LIST_INITIATED,
      ({ payload: { id } }) => {
        return deleteListRequest(id);
      }
    ),
  ]);
}

export default taskWatchers;
