import { createAction } from "@reduxjs/toolkit";

const ACTION_TYPES = {
  CREATE_LIST_INITIATED: 'CREATE_LIST_INITIATED',
  CREATE_LIST_SUCCEEDED: 'CREATE_LIST_SUCCEEDED',
  CREATE_LIST_FAILED: 'CREATE_LIST_FAILED',
  UPDATE_LIST_INITIATED: 'UPDATE_LIST_INITIATED',
  UPDATE_LIST_SUCCEEDED: 'UPDATE_LIST_SUCCEEDED',
  UPDATE_LIST_FAILED: 'UPDATE_LIST_FAILED',
  DELETE_LIST_INITIATED: 'DELETE_LIST_INITIATED',
  DELETE_LIST_SUCCEEDED: 'DELETE_LIST_SUCCEEDED',
  DELETE_LIST_FAILED: 'DELETE_LIST_FAILED',
};

export const LIST_ACTION_TYPES = ACTION_TYPES;

// Actions

export const initCreateList = createAction(ACTION_TYPES.CREATE_LIST_INITIATED);
export const createListSucceeded = createAction(ACTION_TYPES.CREATE_LIST_SUCCEEDED);
export const createListFailed = createAction(ACTION_TYPES.CREATE_LIST_FAILED);

export const initUpdateList = createAction(ACTION_TYPES.UPDATE_LIST_INITIATED);
export const updateListSucceeded = createAction(ACTION_TYPES.UPDATE_LIST_SUCCEEDED);
export const updateListFailed = createAction(ACTION_TYPES.UPDATE_LIST_FAILED);

export const initDeleteList = createAction(ACTION_TYPES.DELETE_LIST_INITIATED);
export const deleteListSucceeded = createAction(ACTION_TYPES.DELETE_LIST_SUCCEEDED);
export const deleteListFailed = createAction(ACTION_TYPES.DELETE_LIST_FAILED);