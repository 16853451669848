import React from 'react';
import MainContent from '../../MainContent/MainContent';
import { Button, Card, Col, Empty, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  MODAL_TYPES,
  hideModalAction,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import { selectAllProjects } from '../../../redux/selectors/project.selectors';
import UIButton from '../../../components/ui/UIButton/UIButton';
import { TbPlus } from 'react-icons/tb';
import ProjectCard from '../../../components/projects/ProjectCard/ProjectCard';
import PageSubHeader from '../../../components/ui/PageSubHeader/PageSubHeader';

export default function ProjectPage() {
  const projects = useSelector(selectAllProjects);
  const dispatch = useDispatch();

  const handleCreateProject = () => {
    dispatch(
      showModalAction({
        modalType: MODAL_TYPES.CREATE_PROJECT_MODAL,
      })
    );
  };

  return (
    <MainContent
      title={<PageSubHeader title={'Projects'} />}
      actions={
        <UIButton
          type="primary"
          onClick={handleCreateProject}
          title={'Create Project'}
          icon={TbPlus}
        />
      }
    >
      <Row gutter={20}>
        {projects.map((project) => (
          <Col span={8}>
            <ProjectCard {...project} />
          </Col>
        ))}
      </Row>
      {projects.length === 0 && (
        <Card>
          <Empty
            description={
              <div className='text-center'>
                <p>You don't have any projects. Create a new project.</p>
              </div>
            }
          />
        </Card>
      )}
    </MainContent>
  );
}
