import React from 'react';
import MainContent from '../../MainContent/MainContent';
import { Card, Col, Empty, Row } from 'antd';
import GoalCard from '../../../components/goals/GoalCard/GoalCard';
import UIButton from '../../../components/ui/UIButton/UIButton';
import { TbPlus } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import {
  MODAL_TYPES,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import { selectAllGoals } from '../../../redux/selectors/goal.selectors';

export default function GoalPage() {
  const dispatch = useDispatch();
  const goals = useSelector(selectAllGoals);

  const showCreateGoalPage = () => {
    console.log('showCreateGoalPage');
    dispatch(
      showModalAction({
        modalType: MODAL_TYPES.CREATE_GOAL_MODAL,
        modalData: {},
      })
    );
  };
  return (
    <MainContent
      title={'Goals'}
      actions={
        <UIButton
          type="primary"
          icon={TbPlus}
          title={'New Goal'}
          onClick={showCreateGoalPage}
        />
      }
    >
      <Row gutter={20}>
        {goals.map((goal) => (
          <Col span={8}>
            <GoalCard {...goal} />
          </Col>
        ))}
      </Row>
      {goals.length === 0 && (
        <Card>
          <Empty
            description={
              <div className='text-center'>
                <p>You don't have any goals. Create a new goal.</p>
              </div>
            }
          />
        </Card>
      )}
    </MainContent>
  );
}
