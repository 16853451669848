import { Model, fk, attr } from 'redux-orm';
import { LIST_ACTION_TYPES } from '../actions/list.actions'

class List extends Model {
  static reducer(action, List) {
    switch (action.type) {
      case LIST_ACTION_TYPES.CREATE_LIST_SUCCEEDED:
        List.create(action.payload);
        break;
      case LIST_ACTION_TYPES.UPDATE_LIST_SUCCEEDED:
        List.withId(action.payload.id).update(action.payload);
        break;
      case LIST_ACTION_TYPES.DELETE_LIST_SUCCEEDED:
        List.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }
}
List.modelName = 'List';

List.fields = {
  title: attr(),
  description: attr(),
  complete: attr(),
  project: fk('Project', 'lists'),
  goal: fk('Goal', 'lists'),
  dueDate: attr()
};

export default List;
