import React from 'react';
import './Navigation.scss';
import {
  TbArtboard,
  TbCalendar,
  TbHome,
  TbLayoutKanban,
  TbList,
  TbTarget,
  TbTimeline,
} from 'react-icons/tb';
import { NavLink } from 'react-router-dom';

export default function Navigation({ items = [
  {
    label: 'Overview',
    icon: TbHome,
    link: '/',
  }
] }) {
  return (
    <div className="navigation">
      { items.map(item => (
        <NavLink end className="nav-item" key={item.label} to={item.link}>
          <item.icon size={16} strokeWidth={1.2} />
          {item.label}
        </NavLink>
      ))}
    </div>
  );
}
