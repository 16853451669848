import React from 'react';
import MainContent from '../../MainContent/MainContent';
import TaskList from '../../../components/tasks/TaskList/TaskList';
import { useSelector } from 'react-redux';
import { selectTodayTasks } from '../../../redux/selectors/task.selectors';
import moment from 'moment';
import { selectThisWeekTasks, selectTomorrowTasks } from '../../../redux/selectors/task.selectors';

export default function WeekPage() {
  const tasks = useSelector(selectThisWeekTasks);
  const start = moment().startOf('isoWeek').format('ddd, DD MMM');
  const end = moment().endOf('isoWeek').format('ddd, DD MMM');

  return (
    <MainContent title={`${start} —  ${end}`}>
      <TaskList tasks={tasks} />
    </MainContent>
  );
}
