import React, { useEffect, useState } from 'react';
import './DateUIDropdown.scss';
import { Calendar, Divider } from 'antd';
import CustomCalendar from '../CustomCalendar/CustomCalendar';
import moment from 'moment';

export default function DateUIDropdown({
  placeholder,
  value,
  onChange = () => {},
}) {
  const [open, setOpen] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const ref = React.useRef();
  const [tempDate, setTempDate] = useState(moment());

  const handleDateChange = (val) => {
    console.log(val);
    setTempDate(val);
  };

  const handleDateSelect = (date) => () => {
    let selectedDate = moment();
    if (date === 'today') {
      selectedDate = moment();
    }
    if (date === 'tomorrow') {
      selectedDate = moment().add(1, 'day');
    }
    if (date === 'end-of-week') {
      selectedDate = moment().endOf('week');
    }
    if (date === 'end-of-next-week') {
      selectedDate = moment().add(1, 'week').endOf('week');
    }
    setTempDate(selectedDate);
    onChange(selectedDate);
    setOpen(false);
  };

  useEffect(() => {
    const onBodyClick = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen(false);
    };
    document.body.addEventListener('mousedown', onBodyClick);
    return () => {
      document.body.removeEventListener('mousedown', onBodyClick);
    };
  }, []);

  useEffect(() => {
    const date = moment(value);
    value && setActiveDate(date);
    value && setTempDate(date);
  }, [value]);

  const handleSaveClick = () => {
    setActiveDate(tempDate.toDate());
    onChange(tempDate);
    setOpen(false);
  };

  return (
    <div className="date-ui-dropdown">
      <div className="date-dropdown-trigger" onClick={() => setOpen(!open)}>
        {!activeDate && (
          <div className="placeholder">
            {placeholder ? placeholder : 'Set Date'}
          </div>
        )}
        {activeDate && (
          <div className="select-value">
            {moment(activeDate).format('DD MMM')}
          </div>
        )}
      </div>
      {open && (
        <div className="date-dropdown-actual-dropdown" ref={ref}>
          <div className="pre-select-options">
            <div className="select-option" onClick={handleDateSelect('today')}>
              <span className="key">Today</span>
              <span className="value">{moment().format('ddd, DD MMM')}</span>
            </div>
            <div
              className="select-option"
              onClick={handleDateSelect('tomorrow')}
            >
              <span className="key">Tomorrow</span>
              <span className="value">
                {moment().add(1, 'day').format('ddd, DD MMM')}
              </span>
            </div>
            <div
              className="select-option"
              onClick={handleDateSelect('end-of-week')}
            >
              <span className="key">End of Week</span>
              <span className="value">
                {moment().endOf('week').format('ddd, DD MMM')}
              </span>
            </div>
            <div
              className="select-option"
              onClick={handleDateSelect('end-of-next-week')}
            >
              <span className="key">End of Next Week</span>
              <span className="value">
                {moment().add(1, 'week').endOf('week').format('ddd, DD MMM')}
              </span>
            </div>
          </div>
          <hr className="date-divider" />
          <div className="date-dropdown-calendar">
            {/* <h5>Select Custom Date</h5> */}
            <CustomCalendar
              fullscreen={false}
              mode="month"
              onChange={handleDateChange}
              value={tempDate}
            />
            <div className="calendar-actions">
              <button className="cancel-btn" onClick={() => setOpen(!open)}>Cancel</button>
              <button className="save-btn" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
