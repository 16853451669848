import { combineReducers } from '@reduxjs/toolkit';
import uiReducer from './ui.reducer';
import { createReducer } from 'redux-orm';
import orm from '../orm';

const reducer = combineReducers({
  ui: uiReducer,
  orm: createReducer(orm),
});

export default reducer;
