import { Calendar, DatePicker, Form, Input, Modal, Select } from 'antd';
import React from 'react';
import UIButton from '../../../components/ui/UIButton/UIButton';
import { useDispatch, useSelector } from 'react-redux';
import { hideModalAction } from '../../../redux/reducers/ui.reducer';
import { selectAllProjects } from '../../../redux/selectors/project.selectors';
import { initCreateGoal } from '../../../redux/actions/goal.actions';

export default function CreateGoalModal({ data }) {
  const dispatch = useDispatch();
  const projects = useSelector(selectAllProjects);

  const hideModal = () => {
    dispatch(hideModalAction());
  };

  const handleSubmit = (values) => {
    dispatch(
      initCreateGoal({
        title: values.title,
        project: values.projectId,
        description: values.description,
        dueDate: values.dueDate?.toISOString(),
      })
    );
    hideModal();
  };

  return (
    <Modal
      open={true}
      title="Create a List"
      footer={false}
      onCancel={hideModal}
    >
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item name={'title'} label="Goal Title">
          <Input placeholder="Product Launch" />
        </Form.Item>
        <Form.Item name={'description'} label="Describe your goal">
          <Input.TextArea placeholder="Product Launch" rows={3} />
        </Form.Item>
        <Form.Item name={'project'} label="Project">
          <Select>
            {projects.map((project) => (
              <Select.Option key={project.id} value={project.id}>
                {project.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'dueDate'}
          label="By when do you want to achieve this?"
        >
          <DatePicker
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item>
          <UIButton type="submit" title={'Create Goal'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
