import React, { useRef } from 'react';
import './SingleTask.scss';
import {
  TbAntennaBars5,
  TbChevronRight,
  TbCirclePlus,
  TbClock,
  TbDotsVertical,
  TbEdit,
  TbPlus,
  TbSubtask,
  TbTrash,
} from 'react-icons/tb';
import { Dropdown, Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  initCreateTask,
  initDeleteTask,
  initUpdateTask,
} from '../../../redux/actions/task.actions';
import classNames from 'classnames';
import moment from 'moment';
import UIDropdown from '../../ui/UIDropdown/UIDropdown';
import ProjectDot from '../../ui/ProjectDot/ProjectDot';
import { selectAllGoals } from '../../../redux/selectors/goal.selectors';
import { selectAllProjects } from '../../../redux/selectors/project.selectors';
import DateUIDropdown from '../../ui/DateUIDropdown/DateUIDropdown';

const PriorityCell = ({ id, value }) => {
  const dispatch = useDispatch();

  const handlePriorityChange = (value) => {
    const { value: priorityValue } = value;
    console.log('value', value);
    dispatch(
      initUpdateTask({
        id: id,
        priority: priorityValue,
      })
    );
  };

  return (
    <UIDropdown
      placeholder={'Priority'}
      placeholderIcon={<TbAntennaBars5 size={18} />}
      value={value}
      items={[
        {
          label: 'High',
          value: 'high',
          icon: <ProjectDot color={'#ff4d4f'} />,
        },
        {
          label: 'Medium',
          value: 'medium',
          icon: <ProjectDot color={'#4CAF50'} />,
        },
        {
          label: 'Low',
          value: 'low',
          icon: <ProjectDot color={'#ffc107'} />,
        },
      ]}
      onChange={handlePriorityChange}
    />
  );
};

const TimeEstimateCell = ({ id, value }) => {
  const dispatch = useDispatch();

  const handleEstimateChange = (value) => {
    const { value: estimateValue } = value;
    dispatch(
      initUpdateTask({
        id: id,
        estimate: estimateValue,
      })
    );
  };

  return (
    <UIDropdown
      placeholder={'Estimate'}
      placeholderIcon={<TbClock />}
      value={value}
      items={[
        {
          label: '05 Mins',
          value: '5',
        },
        {
          label: '25 Mins',
          value: '25',
        },
        {
          label: '01 Hour',
          value: '60',
        },
        {
          label: '02 Hours',
          value: '120',
        },
        {
          label: '04 Hours',
          value: '240',
        },
      ]}
      onChange={handleEstimateChange}
    />
  );
};

const GoalCell = ({ id, value }) => {
  const dispatch = useDispatch();
  const goals = useSelector(selectAllGoals);

  const handleEstimateChange = (value) => {
    const { value: goalValue } = value;
    dispatch(
      initUpdateTask({
        id: id,
        goal: goalValue,
      })
    );
  };

  return (
    <UIDropdown
      placeholder={'Goal'}
      placeholderIcon={<TbClock />}
      value={value}
      items={goals.map((goal) => ({
        label: goal.title,
        value: goal.id,
      }))}
      onChange={handleEstimateChange}
    />
  );
};

const ProjectCell = ({ id, value }) => {
  const dispatch = useDispatch();
  const projects = useSelector(selectAllProjects);

  const handleEstimateChange = (value) => {
    const { value: projectValue } = value;
    dispatch(
      initUpdateTask({
        id: id,
        project: projectValue,
      })
    );
  };

  return (
    <UIDropdown
      placeholder={'Goal'}
      placeholderIcon={<TbClock />}
      value={value}
      items={projects.map((project) => ({
        icon: <ProjectDot color={project.color} />,
        label: project.title,
        value: project.id,
      }))}
      onChange={handleEstimateChange}
    />
  );
};

const DateCell = ({ id, value }) => {
  const dispatch = useDispatch();

  const handleDateChange = (value) => {
    const date = value?.toISOString();
    // const { value: projectValue } = value;
    dispatch(
      initUpdateTask({
        id: id,
        dueDate: date,
      })
    );
  };

  return <DateUIDropdown value={value} onChange={handleDateChange} />;
};

const SingleTaskItem = ({
  id,
  title,
  project,
  complete,
  dueDate,
  priority,
  estimate,
  onAddSubtask,
  isSubTask = false,
  goal,
}) => {
  const dispatch = useDispatch();
  const inputTitleRef = useRef();

  const [editTitle, setEditTitle] = React.useState(false);
  const handleTaskComplete = () => {
    dispatch(
      initUpdateTask({
        id: id,
        complete: !complete,
      })
    );
  };

  const handleTitleChange = (e) => {
    if (e.key === 'Enter') {
      dispatch(
        initUpdateTask({
          id: id,
          title: e.target.innerText,
        })
      );
      e.target.blur();
      setEditTitle(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      initDeleteTask({
        id: id,
      })
    );
  };

  return (
    <div
      className={classNames('single-task', {
        complete: complete,
      })}
      id={'single-task-' + id}
    >
      <div className="task-checkbox">
        <div
          className={classNames('checkbox', {
            complete: complete,
          })}
          onClick={handleTaskComplete}
        ></div>
      </div>
      <div
        className="task-title"
        contentEditable={editTitle}
        autoFocus
        onKeyDown={handleTitleChange}
        ref={inputTitleRef}
      >
        {title}
      </div>
      <div className="task-project">
        <ProjectCell id={id} value={project?.id} />
      </div>
      <div className="task-goal">
        <GoalCell id={id} value={goal} />
      </div>
      <div className="task-due-date">
        {/* {dueDate && (
          <span color="blue">{moment(dueDate).format('DD MMM')}</span>
        )} */}
        <DateCell id={id} value={dueDate} />
      </div>
      <div className="task-priority">
        <PriorityCell id={id} value={priority} />
      </div>
      <div className="task-estimate">
        <TimeEstimateCell id={id} value={estimate} />
      </div>
      <div className="task-actions">
        <Dropdown
          menu={{
            items: [
              {
                key: 'add-subtasks',
                label: 'New Subtask',
                icon: <TbSubtask size={18} />,
              },
              {
                key: 'edit',
                label: 'Edit Task',
                icon: <TbEdit size={18} />,
              },
              {
                key: 'delete',
                label: 'Delete Task',
                icon: <TbTrash size={18} />,
              },
            ],
            onClick: (e) => {
              if (e.key === 'add-subtasks') {
                onAddSubtask();
              } else if (e.key === 'edit') {
                setEditTitle(true);
                setTimeout(() => {
                  inputTitleRef.current.focus();
                }, 10);
              } else if (e.key === 'delete') {
                handleDelete();
              }
            }
          }}
          trigger={['click']}
        >
          <button>
            <TbDotsVertical size={18} />
          </button>
        </Dropdown>
      </div>
      {/* {!isSubTask && (
        <div className="task-actions">
          <Tooltip title="Add Subtask">
            <button onClick={onAddSubtask}>
              <TbPlus size={21} />
            </button>
          </Tooltip>
          <Tooltip title="Edit Task">
            <button
              onClick={() => {
                setEditTitle(true);
                setTimeout(() => {
                  inputTitleRef.current.focus();
                }, 10);
                // const classs = `#single-task-${id} .task-title`;
                // console.log(classs);
                // document.querySelector(classs).focus();
              }}
            >
              <TbEdit size={21} />
            </button>
          </Tooltip>
          <Tooltip title="Delete Task">
            <button onClick={handleDelete}>
              <TbTrash size={21} />
            </button>
          </Tooltip>
        </div>
      )} */}
    </div>
  );
};

export default function SingleTask({
  id,
  title = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus.',
  subtasks = [],
  dueDate,
  complete = false,
  project = null,
  priority,
  estimate,
  goal,
}) {
  const [addSubtask, setAddSubtask] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const dispatch = useDispatch();

  const handleCreateSubtask = (e) => {
    if (e.key === 'Enter') {
      dispatch(
        initCreateTask({
          title: e.target.value,
          project: project?.id,
          parent: id,
        })
      );
      e.target.value = '';
    }
  };

  return (
    <div className="single-task-wrap">
      {/* {subtasks.length > 0 && (
        <button className="expand-btn" onClick={() => setExpanded(!expanded)}>
          <TbChevronRight size={24} strokeWidth={1.2} />
        </button>
      )} */}
      <div className="single-task-wrapper">
        <SingleTaskItem
          title={title}
          id={id}
          complete={complete}
          project={project}
          dueDate={dueDate}
          onAddSubtask={() => setAddSubtask(true)}
          estimate={estimate}
          priority={priority}
          goal={goal}
        />

        {subtasks.length > 0 && (
          <div
            className="sub-tasks-exists"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <TbSubtask size={16} strokeWidth={1.2} />
            <span>{subtasks.length} subtasks</span>
          </div>
        )}
        {(expanded || addSubtask) && (
          <div className="task-subtasks">
            {subtasks.map((item, index) => {
              return (
                <SingleTaskItem
                  key={index}
                  id={item.id}
                  title={item.title}
                  complete={item.complete}
                  project={project}
                  isSubTask={true}
                />
              );
            })}
            {addSubtask && (
              <div className="add-subtask">
                <div className="single-task">
                  <div className="task-checkbox">
                    <div className="checkbox"></div>
                  </div>
                  <div className="task-title">
                    <input
                      placeholder="Add a subtask"
                      onKeyDown={handleCreateSubtask}
                      autoFocus={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
