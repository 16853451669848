import { call, put } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { createListFailed, createListSucceeded, deleteListFailed, deleteListSucceeded, updateListFailed, updateListSucceeded } from "../actions/list.actions";
import { message } from "antd";

export function* createListRequest(values) {
  try {
    const id = uuid();
    const list = {
      id,
      ...values,
    };
    yield put(createListSucceeded(list));
    message.success("List created successfully");
  } catch (error) {
    yield put(createListFailed({ error }));
  }
}

export function* updateListRequest(id, update) {
  try {
    yield put(updateListSucceeded({ id, update }));
    message.success("List updated successfully");
  } catch (error) {
    yield put(updateListFailed({ error }));
  }
}
export function* deleteListRequest(id) {
  console.log('dd', id);
  try {
    yield put(deleteListSucceeded({ id }));
    message.success("List deleted successfully");
  } catch (error) {
    yield put(deleteListFailed({ error }));
  }
}
