import React from 'react';
import { Calendar } from 'antd';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import './CustomCalendar.scss';
import dayjs from 'dayjs';

function CustomHeader({ value, type, onChange, onTypeChange }) {
  // Custom rendering logic to create a header similar to the uploaded image
  // You may need to add additional HTML/CSS for exact styling

  return (
    <div className="calendar-customer-header">
      {/* Custom left arrow button */}
      <button onClick={() => onChange(value.clone().subtract(1, 'month'))}>
        <TbChevronLeft size={24} />
      </button>

      {/* Month and Year display */}
      <span className="month-value">{value.format('MMM YYYY')}</span>

      {/* Custom right arrow button */}
      <button onClick={() => onChange(value.clone().add(1, 'month'))}>
        <TbChevronRight size={24} />
      </button>
    </div>
  );
}

function CustomCalendar({ value, onChange }) {
  const currentValue = dayjs(value.toDate());
  return (
    <Calendar
      value={currentValue}
      fullscreen={false}
      onChange={onChange}
      headerRender={({ value, type, onChange, onTypeChange }) => {
        return (
          <CustomHeader
            value={value}
            type={type}
            onChange={onChange}
            onTypeChange={onTypeChange}
          />
        );
      }}
    />
  );
}

export default CustomCalendar;
