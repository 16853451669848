import { createSelector as createOrmSelector } from 'redux-orm';
import orm from '../orm';
import moment from 'moment';
import { populateTask } from './task.selectors';

export const selectAllProjects = createOrmSelector(orm, ({ Project }) => {
  return Project.all()
    .toModelArray()
    .map((project) => {
      return {
        ...project.ref,
        lists: project.lists.toRefArray(),
      };
    });
});

export const selectSingleProject = (id) =>
  createOrmSelector(orm, ({ Project }) => {
    return Project.withId(id)?.ref;
  });

export const selectSingleProjectWithData = (id) =>
  createOrmSelector(orm, ({ Project }) => {
    const project = Project.withId(id);
    console.log(project);
    return {
      ...project?.ref,
      lists: project?.lists?.toModelArray().map((list) => {
        return {
          ...list.ref,
          tasks: list?.tasks?.toModelArray().map(populateTask),
        };
      }),
    };
  });
