import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initCreateTask } from '../../../redux/actions/task.actions';
import moment from 'moment';
import { hideModalAction } from '../../../redux/reducers/ui.reducer';
import { selectAllProjects } from '../../../redux/selectors/project.selectors';
import { useForm } from 'antd/es/form/Form';
import UIDropdown from '../../../components/ui/UIDropdown/UIDropdown';
import ProjectDot from '../../../components/ui/ProjectDot/ProjectDot';
import {
  TbAntennaBars5,
  TbBorderAll,
  TbCalendar,
  TbClock,
  TbFolder,
  TbTarget,
} from 'react-icons/tb';
import { selectAllGoals } from '../../../redux/selectors/goal.selectors';

export default function CreateTaskModal({ data }) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const projects = useSelector(selectAllProjects);
  const goals = useSelector(selectAllGoals);

  const [activeProject, setActiveProject] = React.useState(null);

  const handleSubmit = (values) => {
    dispatch(
      initCreateTask({
        title: values.title,
        estimate: values.estimate,
        dueDate: values.dueDate,
        project: values.project,
        list: values.list,
        goal: values.goal,
        priority: values.priority,
      })
    );
    hideTaskModal();
  };

  const hideTaskModal = () => {
    dispatch(hideModalAction());
  };

  const projectChanged = (projectId) => {
    console.log('project changed', projectId);
    const project = projects.find((project) => project.id === projectId);
    setActiveProject(project);
  };

  useEffect(() => {
    if (!data) return;
    if (data.projectId) {
      form.setFieldValue('project', data.projectId);
      setActiveProject(
        projects.find((project) => project.id === data.projectId)
      );
    }
    if (data.listId) {
      form.setFieldValue('list', data.listId);
    }
  }, [data, form]);

  return (
    <Modal
      open={true}
      footer={false}
      title="Create a new Task"
      onCancel={hideTaskModal}
      width={800}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item name={'title'}>
          <Input.TextArea placeholder="Give your task a title" rows={3} />
        </Form.Item>
        <Row gutter={10}>
          <Col>
            <Form.Item name="project">
              <UIDropdown
                placeholder={'Project'}
                placeholderIcon={<TbFolder />}
                items={projects.map((project) => ({
                  icon: <ProjectDot color={project.color} />,
                  label: project.title,
                  value: project.id,
                }))}
                onChange={({ value }) => {
                  form.setFieldsValue({ project: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="list">
              <UIDropdown
                placeholder={'Section'}
                placeholderIcon={<TbBorderAll />}
                items={activeProject?.lists.map((project) => ({
                  label: project.title,
                  value: project.id,
                }))}
                onChange={({ value }) => {
                  form.setFieldsValue({ list: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="goal">
              <UIDropdown
                placeholder={'Goal'}
                placeholderIcon={<TbTarget />}
                items={goals.map((project) => ({
                  label: project.title,
                  value: project.id,
                }))}
                onChange={({ value }) => {
                  form.setFieldsValue({ goal: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="dueDate">
              <UIDropdown
                placeholder={'Due Date'}
                placeholderIcon={<TbCalendar />}
                items={[
                  {
                    label: 'Today',
                    value: moment().toISOString(),
                  },
                  {
                    label: 'Tomorrow',
                    value: moment().add(1, 'days').toISOString(),
                  },
                  {
                    label: 'End of Week',
                    value: moment().endOf('week').toISOString(),
                  },
                  {
                    label: 'No Due Date',
                    value: null,
                  },
                ]}
                onChange={({ value }) => {
                  form.setFieldsValue({ dueDate: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="priority">
              <UIDropdown
                placeholder={'Priority'}
                placeholderIcon={<TbAntennaBars5 size={18} />}
                items={[
                  {
                    label: 'High',
                    value: 'high',
                    icon: <ProjectDot color={'#ff4d4f'} />,
                  },
                  {
                    label: 'Medium',
                    value: 'medium',
                    icon: <ProjectDot color={'#4CAF50'} />,
                  },
                  {
                    label: 'Low',
                    value: 'low',
                    icon: <ProjectDot color={'#ffc107'} />,
                  },
                ]}
                onChange={({ value }) => {
                  form.setFieldsValue({ priority: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="estimate">
              <UIDropdown
                placeholderIcon={<TbClock />}
                placeholder={'Estimate'}
                items={[
                  {
                    label: '05 Mins',
                    value: '5',
                  },
                  {
                    label: '25 Mins',
                    value: '25',
                  },
                  {
                    label: '01 Hour',
                    value: '60',
                  },
                  {
                    label: '02 Hours',
                    value: '120',
                  },
                  {
                    label: '04 Hours',
                    value: '240',
                  },
                ]}
                onChange={({ value }) => {
                  form.setFieldsValue({ estimate: value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={10}>
          <Col span={6}>
            <Form.Item name="project">
              <Select
                placeholder="Select a project"
                name="project"
                onChange={projectChanged}
              >
                {projects.map((project) => (
                  <Select.Option key={project.id} value={project.id}>
                    {project.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="list">
              <Select placeholder="Select a list" name="list">
                {activeProject?.lists.map((list) => (
                  <Select.Option key={list.id} value={list.id}>
                    {list.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'dueDate'}>
              <DatePicker placeholder="Due Date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="estimate">
              <Select placeholder="Time Estimate">
                <Select.Option value="5">05 Mins</Select.Option>
                <Select.Option value="25">25 Mins</Select.Option>
                <Select.Option value="60">01 Hour</Select.Option>
                <Select.Option value="120">02 Hours</Select.Option>
                <Select.Option value="240">04 Hours</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Button type="primary" htmlType="submit">
              Create Task
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
