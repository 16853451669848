import taskWatchers from './task.watcher';
import projectWatchers from './project.watcher';
import listWatchers from './list.watcher';
import goalWatchers from './goal.watcher';

const watchers = [
    taskWatchers,
    projectWatchers,
    listWatchers,
    goalWatchers
]

export default watchers;