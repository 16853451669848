import { call, put } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { createTaskFailed, createTaskSucceeded, deleteTaskFailed, deleteTaskSucceeded, updateTaskFailed, updateTaskSucceeded } from "../actions/task.actions";
import { message } from "antd";

export function* createTaskRequest(values) {
  try {
    const id = uuid();
    const task = {
      id,
      ...values,
    };
    yield put(createTaskSucceeded(task));
    message.success("Task created successfully");
  } catch (error) {
    yield put(createTaskFailed({ error }));
  }
}

export function* updateTaskRequest(id, update) {
  try {
    yield put(updateTaskSucceeded({ id, update }));
    message.success("Task updated successfully");
  } catch (error) {
    yield put(updateTaskFailed({ error }));
  }
}
export function* deleteTaskRequest(id) {
  console.log('dd', id);
  try {
    yield put(deleteTaskSucceeded({ id }));
    message.success("Task deleted successfully");
  } catch (error) {
    yield put(deleteTaskFailed({ error }));
  }
}
