import { createSelector as createOrmSelector } from 'redux-orm';
import orm from '../orm';
import { populateTask } from './task.selectors';

export const selectAllGoals = createOrmSelector(orm, ({ Goal }) => {
  return Goal.all().toRefArray();
});

export const selectSingleGoal = (id) =>
  createOrmSelector(orm, ({ Goal }) => {
    return Goal.withId(id)?.ref;
  });

export const selectSingleGoalWithData = (id) =>
  createOrmSelector(orm, ({ Goal }) => {
    const goal = Goal.withId(id);
    console.log(goal);
    return {
      ...goal?.ref,
      lists: goal?.lists?.toModelArray().map((list) => {
        return {
          ...list.ref,
          tasks: list?.tasks?.toModelArray().map(populateTask),
        };
      }),
    };
  });
