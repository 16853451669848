import { createAction } from "@reduxjs/toolkit";

const ACTION_TYPES = {
  CREATE_TASK_INITIATED: 'CREATE_TASK_INITIATED',
  CREATE_TASK_SUCCEEDED: 'CREATE_TASK_SUCCEEDED',
  CREATE_TASK_FAILED: 'CREATE_TASK_FAILED',
  UPDATE_TASK_INITIATED: 'UPDATE_TASK_INITIATED',
  UPDATE_TASK_SUCCEEDED: 'UPDATE_TASK_SUCCEEDED',
  UPDATE_TASK_FAILED: 'UPDATE_TASK_FAILED',
  DELETE_TASK_INITIATED: 'DELETE_TASK_INITIATED',
  DELETE_TASK_SUCCEEDED: 'DELETE_TASK_SUCCEEDED',
  DELETE_TASK_FAILED: 'DELETE_TASK_FAILED',
};

export const TASK_ACTION_TYPES = ACTION_TYPES;

// Actions

export const initCreateTask = createAction(ACTION_TYPES.CREATE_TASK_INITIATED);
export const createTaskSucceeded = createAction(ACTION_TYPES.CREATE_TASK_SUCCEEDED);
export const createTaskFailed = createAction(ACTION_TYPES.CREATE_TASK_FAILED);

export const initUpdateTask = createAction(ACTION_TYPES.UPDATE_TASK_INITIATED);
export const updateTaskSucceeded = createAction(ACTION_TYPES.UPDATE_TASK_SUCCEEDED);
export const updateTaskFailed = createAction(ACTION_TYPES.UPDATE_TASK_FAILED);

export const initDeleteTask = createAction(ACTION_TYPES.DELETE_TASK_INITIATED);
export const deleteTaskSucceeded = createAction(ACTION_TYPES.DELETE_TASK_SUCCEEDED);
export const deleteTaskFailed = createAction(ACTION_TYPES.DELETE_TASK_FAILED);