import React from 'react';

export default function ProjectDot({ color }) {
  return (
    <span
      style={{
        width: '12px',
        height: '12px',
        display: 'inline-block',
        borderRadius: '50%',
        background: color,
      }}
    ></span>
  );
}
