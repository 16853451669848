import { createAction, createReducer } from '@reduxjs/toolkit';

export const UiActionTypes = {
  SHOW_MODAL_ACTION: 'SHOW_MODAL_ACTION',
  HIDE_MODAL_ACTION: 'HIDE_MODAL_ACTIONS',
  SHOW_DRAWER_ACTION: 'SHOW_DRAWER_ACTION',
  HIDE_DRAWER_ACTION: 'HIDE_DRAWER_ACTION',
  UPDATE_VIEW_CONFIG_VALUE: 'UPDATE_VIEW_CONFIG_VALUE',
};

export const DRAWER_TYPES = {
  IMPORT_TASKS: 'IMPORT_TASKS',
};

export const MODAL_TYPES = {
  CREATE_TASK_MODAL: 'CREATE_TASK_MODAL',
  CREATE_PROJECT_MODAL: 'CREATE_PROJECT_MODAL',
  CREATE_LIST_MODAL: 'CREATE_LIST_MODAL',
  CREATE_GOAL_MODAL: 'CREATE_GOAL_MODAL',
};

export const VIEW_CONFIG = {
  MYTASKS_HIDE_COMPLETED_TASKS: 'myTasksHideCompletedTasks',
  MYTASKS_GROUP_BY: 'myTasksGroupBy',
};

const INITIAL_STATE = {
  isModalActive: false,
  modalType: null,
  modalData: {},
  isDrawerActive: false,
  drawerType: null,
  drawerData: {},
  viewConfig: {
    [VIEW_CONFIG.MYTASKS_HIDE_COMPLETED_TASKS]: false,
  },
};

export const showModalAction = createAction(UiActionTypes.SHOW_MODAL_ACTION);
export const hideModalAction = createAction(UiActionTypes.HIDE_MODAL_ACTION);

export const showDrawerAction = createAction(UiActionTypes.SHOW_DRAWER_ACTION);
export const hideDrawerAction = createAction(UiActionTypes.HIDE_DRAWER_ACTION);

export const updateViewConfigValue = createAction(
  UiActionTypes.UPDATE_VIEW_CONFIG_VALUE
);

export const uiReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(UiActionTypes.SHOW_MODAL_ACTION, (state, action) => {
      state.isModalActive = true;
      state.modalType = action.payload.modalType;
      state.modalData = action.payload.modalData;
    })
    .addCase(UiActionTypes.HIDE_MODAL_ACTION, (state, action) => {
      state.isModalActive = false;
      state.modalType = null;
      state.modalData = action.payload || {};
    })
    .addCase(UiActionTypes.SHOW_DRAWER_ACTION, (state, action) => {
      const { drawerData, drawerType } = action.payload;
      state.isDrawerActive = true;
      state.drawerType = drawerType;
      state.drawerData = drawerData || {};
    })
    .addCase(UiActionTypes.HIDE_DRAWER_ACTION, (state, action) => {
      state.isDrawerActive = false;
      state.drawerType = null;
      state.drawerData = {};
    })
    .addCase(UiActionTypes.UPDATE_VIEW_CONFIG_VALUE, (state, action) => {
      console.log(action.payload);
      const { key, value } = action.payload;
      if(!state.viewConfig) {
        state.viewConfig = {};
      }
      state.viewConfig[key] = value;
    });
});

export default uiReducer;
