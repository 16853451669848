import React from 'react';
import MainContent from '../../MainContent/MainContent';
import TaskList from '../../../components/tasks/TaskList/TaskList';
import { useSelector } from 'react-redux';
import { selectTodayTasks } from '../../../redux/selectors/task.selectors';
import moment from 'moment';
import { selectThisWeekTasks, selectTomorrowTasks } from '../../../redux/selectors/task.selectors';

export default function TomorrowPage() {
  const tasks = useSelector(selectTomorrowTasks);
  const date = moment().add(1, 'day').format('ddd, DD MMM');

  return (
    <MainContent title={`Tomorrow —  ${date}`}>
      <TaskList tasks={tasks} />
    </MainContent>
  );
}
