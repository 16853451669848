import logo from './logo.svg';
import './App.scss';
import { Layout } from 'antd';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import DashboardPage from './containers/DashboardPage/DashboardPage';
import Sidebar from './components/core/Sidebar/Sidebar';
import ModalWrapper from './containers/modals/ModalWrapper/ModalWrapper';
import TomorrowPage from './containers/tasks/TomorrowTasks/TomorrowTasks';
import WeekPage from './containers/tasks/WeekTasks/WeekTasks';
import SomedayPage from './containers/tasks/SomedayTasks/SomedayTasks';
import PastDuePage from './containers/tasks/PastDue/PastDue';
import GoalPage from './containers/goals/GoalPage/GoalPage';
import ProjectPage from './containers/projects/ProjectPage/ProjectPage';
import StrategyPage from './containers/strategies/StrategyPage/StrategyPage';
import ProjectSinglePage from './containers/projects/ProjectSinglePage/ProjectSinglePage';
import GoalSinglePage from './containers/goals/GoalSinglePage/GoalSinglePage';
import WelcomeModal from './containers/modals/WelcomeModal/WelcomeModal';
import ProjectPageLayout from './containers/projects/ProjectPageLayout/ProjectPageLayout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Layout.Sider width={240}>
            <Sidebar />
          </Layout.Sider>
          <Layout.Content className="main-content">
            <Layout.Content className="main-content-wrapper">
              <Routes>
                <Route path="/past" element={<PastDuePage />} />
                <Route path="/" element={<DashboardPage />} />
                <Route path="/tomorrow" element={<TomorrowPage />} />
                <Route path="/week" element={<WeekPage />} />
                <Route path="/someday" element={<SomedayPage />} />
                <Route path="/goals" element={<GoalPage />} />
                <Route path="/goals/:id" element={<GoalSinglePage />} />
                <Route path="/projects" element={<ProjectPage />} />
                <Route path="/projects/:id" element={<ProjectPageLayout />}>
                  <Route path='' element={<Navigate to='tasks' />} />
                  <Route path='tasks' element={<ProjectSinglePage />} />
                  <Route path='goals' element={<ProjectSinglePage />} />
                </Route>
                <Route path="/strategies" element={<StrategyPage />} />
              </Routes>
              <WelcomeModal />
            </Layout.Content>
            <Layout.Footer className="main-footer">
              <div className="leftnav">
                <a href="">How it works</a>
                <a href="">Features</a>
                <a href="">Help Desk</a>
                <a href="">About Us</a>
                <a href="">Our Blog</a>
              </div>
              <div className="rightnav">
                <a href="">Privacy Policy</a>
                <a href="">Terms of Use</a>
                <a href="">Refund Policy</a>
              </div>
            </Layout.Footer>
          </Layout.Content>
        </Layout>
        <ModalWrapper />
      </BrowserRouter>
    </div>
  );
}

export default App;
