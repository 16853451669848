import React from 'react';
import MainContent from '../../MainContent/MainContent';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSingleProject,
  selectSingleProjectWithData,
} from '../../../redux/selectors/project.selectors';
import TaskList from '../../../components/tasks/TaskList/TaskList';
import {
  MODAL_TYPES,
  showModalAction,
} from '../../../redux/reducers/ui.reducer';
import { selectSingleGoalWithData } from '../../../redux/selectors/goal.selectors';
import Navigation from '../../../components/ui/Navigation/Navigation';
import PageSubHeader from '../../../components/ui/PageSubHeader/PageSubHeader';
import { TbTarget } from 'react-icons/tb';

export default function GoalSinglePage() {
  const params = useParams();
  const goalId = params?.id;
  const dispatch = useDispatch();

  const goal = useSelector(selectSingleGoalWithData(goalId));

  console.log('project', goal);

  const showCreateListModal = () => {
    dispatch(
      showModalAction({
        modalType: MODAL_TYPES.CREATE_LIST_MODAL,
        modalData: {
          goalId: goalId,
        },
      })
    );
  };

  return (
    <MainContent
      title={
        <PageSubHeader title={'Goals'} icon={TbTarget} heading={goal?.title} />
      }
      navigation={<Navigation />}
    >
      {goal?.lists?.map((list) => (
        <TaskList
          tasks={list.tasks}
          goalId={goal?.id}
          listId={list.id}
          key={list.id}
          title={list.title}
        />
      ))}
      <button onClick={showCreateListModal}>Create List</button>
    </MainContent>
  );
}
