import { all, takeLatest } from 'redux-saga/effects';
import { createProjectRequest, updateProjectRequest } from '../requests/project.requests';
import { PROJECT_ACTION_TYPES } from '../actions/project.actions';

function* taskWatchers() {
  yield all([
    takeLatest(PROJECT_ACTION_TYPES.CREATE_PROJECT_INITIATED, ({ payload }) => {
      console.log(payload);
      return createProjectRequest(payload);
    }),
    takeLatest(
      PROJECT_ACTION_TYPES.UPDATE_PROJECT_INITIATED,
      ({ payload: { id, ...rest } }) => {
        return updateProjectRequest(id, rest);
      }
    ),
    // takeLatest(
    //   PROJECT_ACTION_TYPES.DELETE_PROJECT_INITIATED,
    //   ({ payload: { id, ...rest } }) => {
    //     return deleteTaskRequest(id, rest);
    //   }
    // ),
  ]);
}

export default taskWatchers;
