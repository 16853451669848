import { call, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import {
  createProjectFailed,
  createProjectSucceeded,
  updateProjectFailed,
  updateProjectSucceeded,
} from '../actions/project.actions';
import moment from 'moment';

export function* createProjectRequest(values) {
  console.log(values);
  const dateNow = moment().toISOString();
  try {
    const id = uuid();
    const project = {
      id,
      createdAt: dateNow,
      updatedAt: dateNow,
      ...values,
    };
    yield put(createProjectSucceeded(project));
  } catch (error) {
    console.log(error);
    yield put(createProjectFailed({ error }));
  }
}

export function* updateProjectRequest(id, update) {
  try {
    yield put(updateProjectSucceeded({ id, update }));
  } catch (error) {
    yield put(updateProjectFailed({ error }));
  }
}
