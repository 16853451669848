import { createSelector } from '@reduxjs/toolkit';

/** Ui State Selectors */
const selectUiState = (state) => state.ui;

export const selectModalActive = createSelector(selectUiState, (ui) => {
  if (ui.isModalActive) {
    return {
      active: true,
      type: ui.modalType,
      data: ui.modalData,
    };
  }
  return null;
});

export const selectViewConfig = createSelector(selectUiState, (ui) => {
  return ui.viewConfig || {};
});