import { all, takeLatest } from 'redux-saga/effects';
import {
  createGoalRequest,
  updateGoalRequest,
  deleteGoalRequest,
} from '../requests/goal.requests';
import { GOAL_ACTION_TYPES } from '../actions/goal.actions';

function* taskWatchers() {
  yield all([
    takeLatest(GOAL_ACTION_TYPES.CREATE_GOAL_INITIATED, ({ payload }) => {
      return createGoalRequest(payload);
    }),
    takeLatest(
      GOAL_ACTION_TYPES.UPDATE_GOAL_INITIATED,
      ({ payload: { id, ...rest } }) => {
        return updateGoalRequest(id, rest);
      }
    ),
    takeLatest(
      GOAL_ACTION_TYPES.DELETE_GOAL_INITIATED,
      ({ payload: { id } }) => {
        return deleteGoalRequest(id);
      }
    ),
  ]);
}

export default taskWatchers;
