import React, { useEffect } from 'react';
import './CreateProjectModal.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  initCreateProject,
  initUpdateProject,
} from '../../../redux/actions/project.actions';
import {
  MODAL_TYPES,
  hideModalAction,
} from '../../../redux/reducers/ui.reducer';
import './CreateProjectModal.scss';
import { TbCheck } from 'react-icons/tb';
import { selectSingleProject } from '../../../redux/selectors/project.selectors';

const colors = [
  '#F875AA',
  '#CE5A67',
  '#7752FE',
  '#164863',
  '#748E63',
  '#0174BE',
  '#ED7D31',
  '#7743DB',
  '#3D30A2',
  '#FF6C22',
  '#EC8F5E',
];

export default function CreateProjectModal({ data }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [checkedColor, setCheckedColor] = React.useState(colors[0]);

  const projectId = data?.projectId;
  const editEnabled = !!projectId;

  const project = useSelector(selectSingleProject(projectId));

  useEffect(() => {
    if (project) {
      form.setFieldValue('title', project.title);
      form.setFieldValue('description', project.description);
      setCheckedColor(project?.color);
    }
  }, [form, project]);

  const handleCreateProject = (values) => {
    dispatch(
      initCreateProject({
        title: values.title,
        description: values.description,
        color: checkedColor,
      })
    );
    hideModal();
  };

  const submitHandler = (values) => {
    if(editEnabled) {
      handleUpdateProject(values);
    } else {
      handleCreateProject(values);
    }
  }

  const handleUpdateProject = (values) => {
    dispatch(
      initUpdateProject({
        id: projectId,
        title: values.title,
        description: values.description,
        color: checkedColor,
      })
    );
    hideModal();
  };

  const hideModal = () => {
    dispatch(
      hideModalAction({
        modalType: MODAL_TYPES.CREATE_PROJECT_MODAL,
      })
    );
  };

  return (
    <Modal
      open={true}
      title={editEnabled ? 'Edit Project' : 'Create a new Project'}
      footer={false}
      onCancel={hideModal}
    >
      <Form layout="vertical" onFinish={submitHandler} form={form}>
        <Form.Item label="Give your project a title" name={'title'}>
          <Input placeholder="Give your project a title" size="large" />
        </Form.Item>
        <Form.Item label="Description" name={'description'}>
          <Input.TextArea
            placeholder="Description"
            size="large"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item label="Select Color">
          <div className="color-selector">
            {colors.map((color, index) => (
              <span
                className="single-color"
                style={{
                  backgroundColor: color,
                }}
                key={index}
                onClick={() => setCheckedColor(color)}
              >
                {color === checkedColor && (
                  <TbCheck strokeWidth={4} size={32} color="#fff" />
                )}
              </span>
            ))}
          </div>
        </Form.Item>
        <Form.Item>
          {editEnabled ? (
            <Button type="primary" htmlType="submit" size="large">
              Update Project
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" size="large">
              Create Project
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
