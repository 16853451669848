import React, { useEffect, useRef } from 'react';
import './UIDropdown.scss';
import classNames from 'classnames';
import { TbSearch } from 'react-icons/tb';

const SingleItem = ({ icon, label, value, onClick }) => {
  return (
    <div className="ui-dropdown-single-item" onClick={onClick}>
      {icon && <span className="icon-wrap">{icon}</span>}
      <span className="label-wrap">{label}</span>
    </div>
  );
};

export default function UIDropdown({
  items = [],
  placeholder,
  placeholderIcon,
  onChange,
  value,
}) {
  const [open, setOpen] = React.useState(false);
  const ref = useRef();
  const [activeItem, setActiveItem] = React.useState(null);

  useEffect(() => {
    const onBodyClick = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen(false);
    };
    document.body.addEventListener('mousedown', onBodyClick);
    return () => {
      document.body.removeEventListener('mousedown', onBodyClick);
    };
  }, []);

  useEffect(() => {
    const item = items.find((item) => item.value === value);
    setActiveItem(item);
  }, [value, items]);

  const showDropdown = () => {
    setOpen(!open);
  };

  const hideDropdown = () => {
    setOpen(false);
  };

  const onClickItemHandler = (item) => {
    hideDropdown();
    setActiveItem(item);
    onChange(item);
  };

  return (
    <div
      className={classNames('ui-dropdown', {
        opened: open,
      })}
    >
      <div
        className={classNames('ui-dropdown-trigger', {
          'has-value': activeItem,
        })}
        onClick={showDropdown}
      >
        {activeItem ? (
          <SingleItem {...activeItem} />
        ) : (
          <div className="item-placeholder">
            <span className="p-icon">{placeholderIcon}</span>
            <span className="text">{placeholder}</span>
          </div>
        )}
      </div>
      {open && (
        <div className="ui-dropdown-list" ref={ref}>
          <div className="dropdown-search">
            <input placeholder="Search..." autoFocus />
            <span className="search-icon">
              <TbSearch />
            </span>
          </div>
          <div className="dropdown-results">
            {items.map((item) => (
              <SingleItem
                key={item.value}
                icon={item.icon}
                label={item.label}
                value={item.value}
                onClick={() => onClickItemHandler(item)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
